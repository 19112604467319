.explore-container {
  padding: 70px 50px;
}
.explore__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.explore-container h2 {
  font-weight: bold;
}
.explore__feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  cursor: pointer;
  width: 15%;
  position: relative;
  z-index: 10;
  aspect-ratio: 4/5;
}
.explore__feature:hover {
  box-shadow: 0 5px 25px rgba(255, 202, 0, 0.5);
}
.explore__feature::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 105%;
  transform: translate(-50%, -50%) rotate(2deg);
  z-index: -10;
  background: linear-gradient(to right, #f02b7d, #29d5be);
  border-radius: 15px;
  transition: 0.3s all ease;
}
.explore__feature:hover.explore__feature::before {
  transform: translate(-50%, -50%) rotate(-180deg);
}

.explore__feature img {
  width: 100%;
  aspect-ratio: 4/5;
  object-fit: cover;
  border-radius: 4px;
}

.explore__feature span {
  color: #3a3a3a;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #edd885;
  width: 100%;
  margin: 0;
  padding: 5px;
  text-align: center;
}

.category__heading {
  color: #333333;
}

.explore__container button {
  margin-top: 10px;
  padding: 10px 25px;
  color: #fff;
  background-color: rgb(28, 146, 146);
  border-radius: 50px;
  border: 0;
  scale: 1;
  transition: all 0.3s ease-in-out;
}

.explore__container button:hover {
  background-color: rgb(14, 136, 136);
  scale: 1.05;
}

@media screen and (max-width: 786px) {
  .explore-container {
    padding: 50px 20px;
    padding-bottom: 0;
  }
  .explore__container {
    justify-content: space-between;
    padding: 0;
  }
  .explore__feature {
    width: 30%;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 600px) {
  .explore__feature {
    width: 45%;
    margin-bottom: 50px;
  }
}
