.team__box {
}
.about__heading {
  color: #474747;
  font-size: 28px;
}
.team__member {
  position: relative;
  padding: 70px 50px;
}
.team__box img {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  background: transparent;
  display: block;
  margin: auto;
}
.team-cont {
  padding: 20px;
}
.team {
  margin: 20px 0;
}
.team img {
  width: 80%;
  height: 300px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.team p:nth-child(2) {
  font-size: 16px !important;
  margin-top: 30px !important;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}
.team p {
  font-size: 14px !important;
}
.team-desc {
  height: 200px;
  overflow-y: scroll;
}
.about-5000 {
  font-size: 200px !important;
  font-weight: bold;
  background-image: url("../../images/About/about.jpg");
  background-clip: text;
  -webkit-background-clip: text;
  /* For Safari/WebKit browsers */
  color: transparent;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
  font-weight: bold;
  text-align: center;
}
.team__member {
  width: 90%;
  display: block;
  margin: auto;
}
.our-mission {
  padding: 0;
}
.our-mission img {
  width: 100%;
}
.jcc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
@media screen and (max-width: 992px) {
  .about__heading {
    color: #474747;
    font-size: 18px;
    font-weight: 700;
  }

  .about-5000 {
    font-size: 100px !important;
  }

  .team__box img {
    width: 90%;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    background: transparent;
    display: inline;
    margin: auto;
  }

  .team__box h4 {
    color: #474747 !important;
    font-weight: 600;
    font-size: 0.9rem !important;
  }

  .team__box p {
    font-size: 0.8rem !important;
    font-weight: 100;
  }

  .team__box i {
    font-size: 1rem !important;
    margin: px;
  }

  .about-small-text {
    font-size: 13px !important;
  }
  .team__member {
    padding: 50px 20px;
  }
  .team__member .arrow__left,
  .team__member .arrow__right {
    display: none;
  }
}
