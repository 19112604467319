.contact-container {
  height: 70vh;
  background-position: center !important;
  background-size: cover !important;
}
.contact-bckd {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding: 50px 200px;
}
.contact-bckd h2 {
  font-weight: 600;
}
.contact-bckd span {
  text-align: center;
}
.contact-feature {
  padding: 50px 100px;
  text-align: center;
  background-color: #f7f7f7;
}
.cf .icon {
  font-size: 70px;
  background-color: #ffca00;
  padding: 8px;
  border-radius: 100px;
  margin-bottom: 10px;
}
.c-details {
  height: 500px;
  background-position: center !important;
  background-size: cover !important;
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.c-details-bckd {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cntc {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px 20px;
  border-radius: 15px;
}
.cntc h5 {
  font-weight: bold;
  text-decoration: underline !important;
  margin-bottom: 20px;
}
.contact__form .social__media__links {
  margin-top: 20px;
}
.contact__form .icon {
  font-size: 45px;
  background-color: #000;
  color: #fff;
  &:hover {
    background-color: #222;
  }
}

/*! CONTACT FORM  */
.contact-form {
  padding: 70px 20px;
}

.contact-form-input input:focus {
  outline: none !important;
}
.contact-form-input textarea:focus {
  outline: none !important;
}
.contact-form-input input,
textarea {
  border: 2px solid rgb(180, 180, 180);
  font-size: 16px;
  background-color: transparent;
  padding: 7px !important;
  border-radius: 6px;
}
.contact-form-input input::placeholder {
  background-color: transparent;
}

.contact-form-input {
  position: relative;
}
.contact-form-lable {
  position: absolute;
  top: -1px;
  left: 30px;
  margin: 0;
  font-size: 13px;
  color: gray;
  background-color: white;
  padding: 0 10px;
}
.contact-submit-button:hover {
  background-color: white !important;
  color: black !important;
}
.google-map {
  padding: 70px 100px;
  background-color: #f7f7f7;
}
.google-map iframe {
  width: 100%;
  height: 350px;
  border-radius: 25px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 992px) {
  .contact-feature {
    padding: 50px 20px;
  }
  .cf .icon {
    font-size: 50px;
    padding: 5px;
  }
  .cf h6 {
    font-size: 14px;
  }
  .contact-bckd {
    padding: 50px 10px;
  }
  .google-map {
    padding: 70px 20px;
  }
}
