.home-blog-container {
  padding: 50px 100px;
  background-color: #f7f7f7;
}
.home-blog-container h2 {
  font-weight: bold;
}
.recent-blog-img {
  width: 35% !important;
}
.recent-blog-img img {
  width: 100%;
  object-fit: cover;
}
.blogss {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
  margin-top: 30px;
  justify-content: space-between;
}
.home-blog {
  width: 48%;
  background-color: #fff;
  border-radius: 15px !important;
}
.recent-blog-content .blog-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
.home-blog-btn {
  width: 100%;
}
.home-blog-btn button {
  display: block;
  margin: 0 auto;
  background-color: #000;
  padding: 15px 20px;
  outline: transparent;
  border: transparent;
  color: #fff;
  border-radius: 5px;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .home-blog-container {
    padding: 50px 10px;
  }

  .blogss {
    padding: 0px;
  }
  .home-blog {
    width: 100%;
  }
  .home-blog .blog-desc {
    font-size: 14px;
  }

  .recent-blog-content .blog-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }
}
