.site-reviews-container {
  padding: 70px 50px;
  background-color: #f7f7f7;
  overflow: hidden;
}
.site-review {
  padding: 20px;
}
.site_revieww {
  padding: 20px;
  border: 1px solid grey;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.site_revieww img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100px;
  margin-bottom: 20px;
}
.site-review .stars {
  color: #ffca00;
}
.stars .icon {
  font-size: 15px;
}
.review-img {
  width: 20%;
}
.review-content {
  width: 70%;
}
.review-img img {
  width: 100%;
}
.review-text {
  height: 200px;
  overflow-y: scroll;
}

@media screen and (max-width: 992px) {
  .site-reviews-container {
    padding: 50px 20px;
  }
}
