.offer__and__updates {
    background-color: #fecc2aa2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer__and__updates form {
    background-color: transparent !important;
    color: white;
    width: 500px;
    display: block;
    margin: 0 auto;
}

.offer__and__updates form input {
    background-color: transparent !important;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    color: #333333 !important;
    padding-left: 10px !important;
}

.offer__and__updates input::placeholder {
    color: rgba(0, 0, 0, 0.5);
}

.offer__and__updates form input:focus {
    color: #333333 !important;
    border: 1px solid #333333;
    box-shadow: none;
}

.offer__and__updates form button {
    padding: 8px 25px;
    background-color: #333333;
    color: #fff;
    border: 0;
}

@media screen and (max-width:478px) {
    .offer__and__updates form {
        background-color: transparent !important;
        color: white;
        width: 450px;
        display: block;
        margin: 0 auto;
    }

    .offer__and__updates form input {
        padding: 10px 0 10px 15px;
    }

    .offer__and__updates form button {
        padding: 10px 30px !important;
        background-color: #333333;
        color: #fff;
        border: 0;
    }
}