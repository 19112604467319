.header-main {
  height: 100px;
}
.main {
  color: white;
  width: 100%;
  padding: 0;
  transition: all 0.3s ease;
  height: 100px;
}
.main.active {
  height: 100px;
  border: 1px solid red;
  color: white;
  width: 100%;
  padding: 0;
  transition: all 0.3s ease;
}
.fifty__one {
  width: 15%;
}
.fifty__two,
.fifty__one {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fifty__one {
  padding: 0 30px;
}
.fifty__two {
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
}
.navbar {
  height: 100px;
  padding-bottom: 0;
  padding-left: 100px;
  padding-right: 100px;
  color: #666;
  background: transparent;
  transition: 0.3s ease;
  display: flex;
  justify-content: space-between;
}
.navbar.active {
  background: #fff;
  padding-bottom: 0;
  padding-left: 100px;
  padding-right: 100px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.hmlogo {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.logo,
.contact_number {
  width: 20%;
}
.header__whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__whatsapp button {
  font-weight: 500;
  margin-right: 20px;
  font-size: 0.9rem;
}
/* ========================================== SEARCH CONTAINER  */
.search__container {
  position: relative;
  width: 350px;
}
.search__container input {
  width: 100%;
  padding: 8px 0 8px 10px;
  border: 0;
  outline: none;
}
.search__container i {
  color: #666666;
  margin-left: -25px;
}
.searching__box {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: auto;
  text-align: center;
  padding-top: 10px;
  background-color: #fff;
  margin-top: 5px;
}
.searching__box div {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: #fff;
  width: 100%;
}
.searching__box div p {
  padding: 5px;
}
.searching__box div:hover p {
  color: #141414;
  background-color: #eee;
}

.searching__box p {
  font-size: 14px;
  color: #666666;
}

.mobile__search__container {
  width: 100% !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.8);
}

.mobile__search__container input {
  outline: 4px solid #000;
  width: 100% !important;
}

.mobile__search__container .searching__box {
  width: 100%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
  text-align: start;
  padding-left: 10px;
  padding: 10px !important;
}

.mobile__search__container .searching__box div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.mobile__search__container .searching__box p {
  margin: 5px;
}

.logo,
.contact_number span,
.menu_list ul li a {
  font-family: "Montserrat", sans-serif;
  margin: 0 10px;
  font-weight: 400;
  font-size: 13px;
  color: #666;
  &:hover {
    color: #333;
    text-decoration: underline !important;
  }
}
.logo {
  cursor: pointer;
  text-decoration: none !important  ;
}

.menu_list ul {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu_list ul li a {
  font-size: 16px !important;
}

.nav_toggle_button {
  color: #000;
  background-color: #ffca00;
  border-radius: 100px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.wa-icon .icon {
  padding: 5px;
  font-size: 50px;
  border-radius: 100px;
  border: 1px solid green;
  background-color: white;
  color: green;
  &:hover {
    background-color: green;
    color: white !important;
  }
}
.call-icon .icon {
  border: 1px solid #333;
  background-color: white;
  color: #333;
  &:hover {
    background-color: #333;
    color: white !important;
  }
}

@media screen and (max-width: 768px) {
  .fifty__one {
    padding: 0 10px;
  }
  .logo,
  .contact_number {
    width: auto;
  }
  .header__whatsapp {
    display: none;
  }
  .navbar {
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .navbar.active {
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .wa-icon .icon,
  .call-icon .icon {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 390px) {
  .logo h5 {
    font-size: 16px !important;
  }
  .wa-icon .icon,
  .call-icon .icon {
    display: none;
    font-size: 30px;
  }
}
