.testimonials__cont {
  width: 100%;
  background-color: #f7f7f7;
  padding: 70px 10px;
  overflow: hidden !important;
}
.testimonials__cont .slick-arrow {
  display: none !important;
}
.testimonials {
  position: relative;
}
.testimonials__cont .hr-line {
  width: 300px;
  margin-bottom: 20px;
}
.testimonials__cont h2 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.testimonials iframe {
  border-radius: 10px;
  width: 100%;
  height: 250px;
}
.testimonial-slider-btns {
  display: flex;
  justify-content: center;
}
.testimonial-slider-btns .test-slider-right,
.testimonial-slider-btns .test-slider-left {
  background-color: #f7f7f7;
  color: grey;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  border: 1px solid grey;
  margin-left: 20px;
  color: grey;
  padding: 10px;
  &:hover {
    background-color: #ffca00;
    color: #000;
  }
}
@media screen and (max-width: 992px) {
  .testimonials {
    position: relative;
  }
  .testimonials__cont h2 {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  .testimonials__cont .hr-line {
    width: 100px;
  }
  .testimonials__cont p {
    font-weight: 500;
  }
  .testimonials iframe {
    border-radius: 14px;
    width: 100%;
    height: 180px;
  }
}
@media screen and (max-width: 480px) {
  .testimonials iframe {
    border-radius: 14px;
    width: 100%;
    height: 150px;
  }
}
