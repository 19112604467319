.talk__to__bg {
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center !important;
  background-size: cover !important;
}
.ttbg-shade-bg {
  background-color: rgba(91, 91, 13, 0.849);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 50px;
  color: #fff;
}
.ttbg-btn {
  padding: 15px 20px;
  outline: transparent;
  border: transparent;
  color: #000;
  background-color: #edea82;
  border-radius: 100px;
  margin-top: 50px;
}

@media screen and (max-width: 992px) {
  .talk__to__bg {
    padding: 50px 20px;
    text-align: center;
  }
  .ttbg-btn {
    margin-top: 20px;
  }
  .ttbg-shade-bg h2 {
    font-size: 20px;
  }
}
