.w-70-allAdminTrip{
    width: 70%;
}
.w-30-allAdminTrip{
    width: 30%;
}
.txt-sm{
    font-size: 12px !important;
}
.txt-lg{
    font-size: 14px !important;
}
.txt-lg2{
    font-size: 16px !important;
}
.txt-xlg{
    font-size: 20px !important;
}
.txt-xxlg{
    font-size: 25px !important;
}
.cursor-pointer{
    cursor: pointer;
}
.overflow-x{
    overflow-x: scroll !important;
}
.overflow-y{
    overflow-y: scroll !important;
}


/* All booking list page */
#all-booking-list{
    background-color: rgb(244, 244, 244);   
}
#all-booking-list:hover{
    background-color: rgb(235, 235, 235);    
    cursor: pointer;
}
#all-booking-list:active{
    background-color: rgb(227, 227, 227);    
    cursor: pointer;
}
/* #all-booking-list-item{
    background-color: rgba(236, 236, 236, 0.512) !important;
} */
/* All booking list page */


/* add trip component */
.multiselect-wrapper{
    background-color: white !important;
}
/* add trip component */

/* #all-booking-list:hover::before {
    content: "Click and go to details page...";
    position: absolute;
    top: -12%;
    right: 0;
    transform: translateX(-50);
    width: 200px;
    padding: 10px;
    color: #000000;
    font-size: 12px;
    border-radius: 5px;
} */


/* payment page */
.payment-failed-icon {
    font-size: 70px;
    color: red;
    display: inline-block;
    animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite ;
}
  
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
  
.payment-success-icon {
    font-size: 70px;
    color: green;
    display: inline-block;
    animation: rotate-scale-up 2s ease both;
}
  
@keyframes rotate-scale-up {
    0% {
        opacity: 0;
        transform: rotate(-45deg) scale(0);
    }

    100% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
}
/* payment page */
