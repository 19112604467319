.about__main {
  position: relative;
}

.icons__trip {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.i__con {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
}

.i__con i {
  font-size: 40px;
  color: #ffca00;
}

.light__text__cont {
  position: relative;
}

.light__text__cont h2 {
  border-left: 5px solid #333333;
  padding-left: 5px;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 30px;
}

.light_text {
  position: absolute;
  font-size: 70px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.1);
  top: -25px;
  left: 0px;
}

.about__desc {
  font-weight: 200;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.contact__form button {
  border: 0;
  padding: 10px 40px;
  border-radius: 100px;
  color: #000;
  background-color: #fff;
  border: 2px solid #fecb2a;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.contact__form button:hover {
  border: 2px solid #fecc2a98;
  background-color: #fecc2a98;
}

.content {
  display: none;
}

.show__content {
  display: block;
}

.about__tab__buttons button {
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #333333;
  color: #333333 !important;
  width: 30%;
  padding: 10px 0;
  font-weight: 600;
}

.about__tab__buttons button:hover {
  background-color: #ffca00;
  border: 1px solid #333333 !important;
  border: 0;
  color: #000000;
}

.about__tab__buttons button.active {
  background-color: #ffca00;
  border: 1px solid #000000;
  color: #fff;
}

.shd {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  padding: 30px;
}

.shd-accordian {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  padding: 30px 20px !important;
}

.about__main .hr-line {
  width: 50px;
}

.similar__trips__heading {
  font-weight: bold;
  text-align: start;
}

@media screen and (max-width: 768px) {
  .things__to__carry {
    margin-bottom: 0 !important;
  }

  .icons__trip {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .about__tab__buttons {
    text-align: center;
  }

  .i__con {
    width: 60px;
    height: 60px;
  }

  .trip_icon {
    padding: 10px;
    border-radius: 50%;
    font-size: 25px;
  }

  .icons__trip p {
    font-size: 12px;
  }

  .icons__trip span {
    font-size: 13px;
  }

  .light_text {
    font-size: 59px;
    top: -10px;
    left: 0px;
  }

  .light__text__cont h2 {
    font-size: 25px;
  }

  .light__text__cont span {
    font-size: 55px;
  }

  .about__tab__buttons button {
    width: 30%;
    padding: 10px 0;
    font-size: 10px;
    font-weight: bold;
  }

  .similar__trips__heading {
    font-weight: bold;
    text-align: center !important;
  }
  .single-trip-form {
    display: none !important;
  }
}

/*! SINGLE TRIP/ */
.single-trip-container {
  padding: 20px 150px;
  position: relative;
}
.trip-banner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}
.trip-banner-container .img-one {
  width: 65%;
}
.trip-banner-container .img-two {
  width: 32%;
}
.trip-banner-container .img-three {
  width: 32%;
}
.trip-banner-container .img-four {
  width: 65%;
}
.trip-banner-container img {
  width: 100%;
  height: 300px;
  object-fit: cover !important;
  background-position: center !important;
  border-radius: 15px;
}
.single-trip {
  width: 100%;
  display: flex;
  margin-top: 30px;
  gap: 20px;
}
.single-trip-left {
  width: 70%;
}
.single-short-details {
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
.heading-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-short-details h3 {
  font-weight: 600;
}
.share-btns {
  display: flex;
  gap: 10px;
}
.share-btn,
.send-query-btn,
.get-pdf-btn {
  outline: transparent;
  border: transparent;
  padding: 10px 20px;
  border-radius: 100px;
  &:hover {
    opacity: 0.8;
  }
}
.share-btn {
  background-color: rgb(176, 234, 207);
}
.send-query-btn {
  background-color: #fef1ba;
}
.get-pdf-btn {
  background-color: rgb(241, 198, 198);
}
.single-star {
  display: flex;
  align-items: center;
}
.single-star .icon {
  color: #ffca00;
  font-size: 15px;
}
.single-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.details-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 15px;
  gap: 5px;
  .icon {
    color: #ffca00;
    font-size: 30px;
  }
}
.details-card h6 {
  margin: 0;
  font-size: 14px;
  color: grey;
  font-weight: 400;
}
.details-card p {
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}
.cta-btn {
  background-color: #eee;
  border: transparent;
  outline: transparent;
  border-radius: 100px;
  padding: 10px 20px;
  &:hover {
    background-color: #fef1ba;
  }
}
/*! SAFE TRAVEL  */
.safe-travel {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.safe-travel .icon {
  font-size: 55px;
  padding: 10px;
  border-radius: 100%;
  background-color: #fef1ba;
}
/* CTA ACTIONS  */
.cta-details {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 30px 20px;
  position: sticky;
  top: 70px;
  left: 0;
  background-color: #fff;
  z-index: 99;
}
.cta-details p {
  cursor: pointer;
}
/*! OVERVIEW  */
.overview p {
  white-space: pre-wrap;
  margin: 0;
}

/*! GALLERY  */
.single-gallery .g-img-cont {
  padding: 5px;
}
.single-gallery img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}
.video iframe {
  width: 100%;
  height: 350px;
  border-radius: 15px;
}
/*! ITINERARY  */
.itinerary {
}
.accordion-item-day {
  background-color: #eee !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 10px !important;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  height: 100%;
  width: 70px;
}
.itinerary-item {
  margin-bottom: 10px !important;
}
.itinerary-item button:focus {
  border: 0px !important;
  color: #000;
}
/*! INCLUSION & EXCLUSION  */
.inclusion-and-exclusions {
  display: flex;
  gap: 20px;
}
.inclusion,
.exclusion {
  width: 50%;
  padding: 20px;
}
.inclusion h5,
.exclusion h5 {
  background-color: #eee;
}
/*! CANCELLATION  */
.cancellation {
}
/*! terms-and-conditions  */
.terms-and-conditions {
}

/** RIGHT  */
.single-trip-right {
  width: 40%;
}
.right-form {
  position: sticky;
  top: 70px;
  right: 0;
  background-color: #fff;
}
/*! PRICE  */
.price-container {
  border: 1px solid grey;
}
.single-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    background-color: #fef1ba;
    padding: 5px 10px;
    border-radius: 100px;
  }
}
.book-btn {
  width: 100%;
  margin-top: 20px;
  padding: 12px 20px;
  outline: transparent;
  border: transparent;
  background-color: #ffca00;
  border-radius: 100px;
  transition: 0.3s all ease;
  &:hover {
    opacity: 0.8;
  }
}
/*! DATES/BATCHES  */
.batches {
  display: flex;
  justify-content: space-between;
}
.batch-month {
  display: flex;
  gap: 10px;
}
.batch-date {
  width: 100%;
}
.batch-date p {
  width: 100%;
  background-color: #fef1ba;
  padding: 10px;
  border-radius: 10px;
}

/*! CONTACT CTA  */
.contact-cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-cta .icon {
  background-color: rgb(180, 239, 180);
  font-size: 45px;
  padding: 10px;
  color: green;
  border-radius: 100%;
}
.contact-cta button {
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
  background-color: transparent;
  border: 2px solid #ffca00;
  border-radius: 100px;
  &:hover {
    background-color: #ffca00;
  }
}
.query-form-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  scale: 0;
  overflow: hidden;
}
.query-form-container.active {
  scale: 1;
}
.query-bckd {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.query-form {
  width: 50%;
  background-color: #fff;
  padding: 20px;
  color: #333;
  input {
    border-radius: 0px;
  }
  .icon {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
.submit-btn {
  padding: 10px 20px;
  background-color: #ffca00;
  outline: transparent;
  border: transparent;
  &:hover {
    opacity: 0.8;
  }
}
@media screen and (max-width: 992px) {
  .single-trip-container {
    padding: 20px;
  }

  .heading-details {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 20px;
  }
  .single-trip {
    display: flex;
    flex-wrap: wrap;
  }
  .single-trip-left,
  .single-trip-right {
    width: 100%;
  }
  .cta-details {
    overflow-x: scroll;
    display: flex;
    padding: 10px;
  }
  .cta-details p {
    font-size: 10px;
  }
  .inclusion-and-exclusions {
    display: flex;
    flex-wrap: wrap;
  }
  .inclusion,
  .exclusion {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }
  .query-form {
    width: 90%;
  }
  .contact-cta button {
    font-size: 12px;
  }
}
@media screen and (max-width: 700px) {
  .video iframe {
    width: 100%;
    height: 250px;
    border-radius: 15px;
  }
  .cta-btn {
    padding: 10px 10px;
    font-size: 12px;
  }
  .safe-travel {
    .icon {
      font-size: 40px;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 600px) {
  .cta-btn {
    padding: 10px 5px;
    font-size: 10px;
  }

  .trip-banner-container .img-one {
    width: 100%;
  }
  .trip-banner-container .img-two {
    width: 100%;
  }
  .trip-banner-container .img-three {
    width: 100%;
  }
  .trip-banner-container .img-four {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .video iframe {
    width: 100%;
    height: 200px;
    border-radius: 15px;
  }
  .cta-btn {
    padding: 5px;
    font-size: 10px;
    border-radius: 10px;
  }
  .share-btn,
  .send-query-btn,
  .get-pdf-btn {
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  .video iframe {
    width: 100%;
    height: 150px;
    border-radius: 15px;
  }
  .share-btn,
  .send-query-btn,
  .get-pdf-btn {
    font-size: 10px;
  }
}
