/* LOADING  */
.loading-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pl {
  width: 6em;
  height: 6em;
}

.pl__ring {
  animation: ringA 2s linear infinite;
}

.pl__ring--a {
  stroke: #f42f25;
}

.pl__ring--b {
  animation-name: ringB;
  stroke: #f49725;
}

.pl__ring--c {
  animation-name: ringC;
  stroke: #255ff4;
}

.pl__ring--d {
  animation-name: ringD;
  stroke: #f42582;
}

/* Animations */
@keyframes ringA {
  from,
  4% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }

  12% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -335;
  }

  32% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -595;
  }

  40%,
  54% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -660;
  }

  62% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -665;
  }

  82% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -925;
  }

  90%,
  to {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -990;
  }
}

@keyframes ringB {
  from,
  12% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -110;
  }

  20% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -115;
  }

  40% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -195;
  }

  48%,
  62% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  70% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  90% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -305;
  }

  98%,
  to {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }
}

@keyframes ringC {
  from {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  8% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  28% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  36%,
  58% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  66% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  86% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  94%,
  to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@keyframes ringD {
  from,
  8% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  16% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  36% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  44%,
  50% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  58% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  78% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  86%,
  to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}
/* LOADING  */

.back-to-home-btn {
  background-color: #ffca00;
  color: #000;
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
}
.booking-success {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.booking-success .icon {
  font-size: 80px;
  color: green;
  margin-bottom: 10px;
}
.booking-page-container {
  padding: 140px 100px;
  display: flex;
  gap: 30px;
}
.booking-batch-occupancy {
  width: 70%;
}
.price-and-book {
  width: 30%;
}
.offer-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.offer-input-container input {
  border-radius: 0;
}
.offer-input-container button {
  background-color: #ffca00;
  height: 100%;
  outline: transparent;
  border: transparent;
  padding: 6px;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.payment-btn {
  background-color: #ffca00;
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 100px;
  &:hover {
    opacity: 0.8;
  }
}
.edit-col {
  width: 100px;
}
.seldate {
  background-color: #ffca00 !important;
  color: #000 !important;
}
@media screen and (max-width: 992px) {
  .booking-page-container {
    padding: 140px 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .booking-batch-occupancy {
    width: 100%;
  }
  .price-and-book {
    width: 100%;
  }
}

/*! OLD CDOE  */
.booking__page {
  padding: 100px 20px;
}
.content {
  display: none;
}
.show {
  display: block;
}
.book__cont {
  border: 3px solid rgba(0, 0, 0, 0.556);
  border-radius: 15px;
  overflow: hidden;
  min-height: 300px;
  color: #333333;
}
.border__date {
  border: 1px solid rgb(146, 146, 146);
  padding: 15px;
  border-radius: 10px;
}

.date__btn {
  padding: 8px 20px;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid #333333;
  background-color: transparent;
  transition: 0.3s all;
}

.date__btn.active {
  background-color: #333333;
  border: 1px solid #333333;
  color: white !important;
}

.booking__btn__cont {
  position: relative;
}

.arrow__left,
.arrow__right {
  padding: 5px 8px;
  border: 0;
  background-color: white;
  color: black;
  border-radius: 5px;
  position: absolute;
  top: -38px;
}

.arrow__left {
  left: 200px;
}

.arrow__right {
  right: 200px;
}

@media screen and (max-width: 380px) {
  .book_th {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 600px) {
  .booking__page h1 {
    font-size: 20px !important;
  }

  .book_th {
    font-size: 15px;
  }

  .fa-add {
    font-size: 14px;
  }

  .fa-minus {
    font-size: 14px;
  }

  .arrow__left,
  .arrow__right {
    padding: 5px 8px;
    top: -38px;
  }

  .arrow__left {
    left: 50px;
  }

  .arrow__right {
    right: 50px;
  }
}

@media screen and (max-width: 990px) {
  .arrow__left,
  .arrow__right {
    padding: 5px 8px;
    top: -38px;
  }

  .arrow__left {
    left: 50px;
  }

  .arrow__right {
    right: 50px;
  }
}
