.why-high-mountains-container {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}
.why-image-section {
  width: 40%;
  display: flex;
  gap: 20px;
  padding: 20px;
}
.why-left-img-section,
.why-right-img-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.why-left-img-section img,
.why-right-img-section img {
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.why-content-section {
  width: 50%;
  padding: 20px;
}
.why-content-section span:nth-child(1) {
  color: #333;
  text-transform: uppercase;
}
.why-content-section h2 {
  font-weight: bold;
  margin-bottom: 30px;
}
.why-features-container {
  display: flex;
  flex-wrap: wrap;
}
.why-features {
  width: 33%;
  margin-bottom: 30px;
}
.why-features .icon {
  font-size: 50px;
  padding: 5px;
  background-color: #ffca00;
  border-radius: 5px;
}

@media screen and (max-width: 992px) {
  .why-high-mountains-container {
    padding: 50px 20px;
  }
  .why-image-section,
  .why-content-section {
    width: 100%;
  }
  .why-features-container {
    justify-content: space-between;
  }
  .why-features {
    width: 33%;
    padding-left: 10px;
  }
  .why-features span {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .why-features {
    width: 30%;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .why-features p {
    font-size: 14px;
  }
  .why-features span {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .why-features {
    width: 50%;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .why-features p {
    font-size: 14px;
  }
  .why-features span {
    font-size: 14px;
  }
}
