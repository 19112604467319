.blog {
  height: 300px;
  background-position: center !important;
  background-size: cover !important;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}
.blog-bckd {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 65.62%,
    rgba(0, 0, 0, 1) 94%,
    rgba(0, 0, 0, 1) 96%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
}
.blog-bckd h5 {
  font-size: 18px;
}
.blog-bckd span {
  color: #ffca00;
}

/* SINGLE BLOG PAGE  */
.blog-display-container {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 20px;
}
.blog-display {
  width: 70%;
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #f7f7f7;
}
.blog-display p {
  white-space: pre-wrap;
}
.more-blogs {
  width: 30%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  padding-top: 0;
}
.recent-blog {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 20px;
}
.recent-blog-img {
  width: 40%;
}
.recent-blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recent-blog-content {
  width: 60%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.recent-blog-content h6 {
  font-size: 14px;
  font-weight: 600;
}
.recent-blog-content span {
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .blog-display {
    width: 100%;
  }
  .more-blogs {
    margin-top: 30px;
    width: 100%;
    padding: 0;
  }
}
