.black {
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  background: #141414;
}

.logo,
.contact_number {
  width: 20%;
}

.logo,
.contact_number span,
.menu_list ul li a {
  font-family: "Montserrat", sans-serif;
  margin: 0 10px;
  font-weight: 400;
  font-size: 13px;
}

.menu_list ul {
  margin: 0;
}

.nav_toggle_button i {
  font-size: 20px;
}

@media screen and (max-width: 478px) {
  .logo,
  .contact_number {
    width: auto;
  }
}
