.ffca00 {
  color: #ffca00;
}
.hero-container {
  width: 100%;
}
.hero-bg {
  display: flex;
  width: 100%;
  background: url("../../images/LandingPage/herosection/herobanner.png");
  background-position: bottom;
  background-size: cover;
}
.hero-bckd {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  padding: 50px 100px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.hero-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}
.hero-content h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 60px;
  color: #fff;
}
.hero-search-container {
  width: 350px;
  margin-top: 40px;
}
.hero-search-input {
  width: 100%;
  padding: 20px;
  border-radius: 50px;
  outline: transparent;
}
.search-box {
  background-color: #fff;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  height: 150px;
  overflow-y: scroll;
}
.search-box::-webkit-scrollbar {
  width: 5px;
}
.search-box::-webkit-scrollbar-thumb {
  background-color: #ffca00;
}
.search-box-empty {
  background-color: #fff;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px !important;
}
.search-items {
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #eee;
  }
}
.search-items .icon {
  font-size: 15px;
}
.blob-img {
  width: 140px;
  border-radius: 30px 0 50px 0;
  margin: 0;
}
.hero-search-container {
  position: relative;
}

/* REVIEWS  */
.hero-review-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
}
.reviews {
  width: 60%;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
  display: flex;
  gap: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
}
.review span {
  font-size: 14px;
  color: #fff;
}
.reviews img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}
.reviews-content .star {
  display: flex;
  color: #ffca00;
}
.reviews-content .star .icon {
  font-size: 15px;
}

/* COUNTERS  */
.hero-counters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  justify-content: space-between;
  margin-top: 50px;
}
.counter:nth-child(4) {
  border-right: 0px;
}
.counter {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.counter h4 {
  margin: 0;
}
.counter .icon {
  margin-right: 10px;
  font-size: 50px;
}

@media screen and (max-width: 992px) {
  .hero-bg {
    overflow: hidden;
  }
  .hero-bckd {
    padding: 50px 20px;
    overflow: hidden;
    min-height: calc(100vh - 100px);
    padding-top: 100px;
  }
  .hero-content {
    width: 100%;
    padding: 0;
  }
  .blob-img {
    width: 100px;
    display: none;
  }
  .hero-content h1 {
    font-size: 40px;
    font-weight: bold;
  }
  .hero-text {
  }
  .hero-search-container {
    margin-top: 40px;
  }
  .hero-review-container {
    display: none;
  }
  .hero-counters {
    margin-top: 100px;
  }
  .counter {
    width: 50%;
    border: 0;
  }
  .counter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .counter .icon {
    width: 100%;
    font-size: 35px;
  }
  .counter-content h4 {
    font-size: 14px;
    text-align: center;
  }
}
