.main_landing {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.main_bg_cover {
  /* background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), url(https://ik.imagekit.io/workcations/gallery/triplist/main-cover.jpg); */
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  padding-top: 70px;
}

/* ================================================ LOCATIONS LIST */

.locations__lists img {
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(00, 0, 0, 0.2);
}

.trip__bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trip__bg button {
  position: absolute;
  z-index: 15;
  top: 50%;
  right: 25%;
  padding: 10px 50px;
  background-color: #1f1f1fd0;
  border: 3px solid rgb(10, 158, 136) !important;
  color: rgb(10, 158, 136);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

/* ================================================ MOBILE SCREEEN CSS */
@media screen and (max-width: 478px) {
  .main_bg_cover {
    background-position: center !important;
    background-size: cover !important;
    padding: 0 30px;
    height: 95vh;
  }

  /* ================================================ TRIPS SECTION */

  .page__links a {
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
  }

  .trip__locations a {
    color: white;
    margin: 0 20px;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
  }

  .google__review {
    padding: 10px;
  }

  /* =========================== Testimonials  */
  .testimonials img {
    width: 100%;
  }

  .review__img {
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #9999992c;
    border-radius: 15px;
  }

  /* .review__img:hover {
        background-color: #333333;
    }

    .review__img:hover h3,
    .review__img:hover i,
    .review__img:hover h5 {
        color: #fff !important;
    } */

  .review__img img,
  .trip__advisor img {
    width: 60px !important;
    height: 60px !important;
  }

  .certified__container {
    min-height: 200px;
  }

  .certified__container img {
    width: 120px !important;
  }
}
