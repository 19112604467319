.form__body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), url(https://ik.imagekit.io/workcations/gallery/triplist/main-cover.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

form {
    /* background-color: rgba(226, 226, 226, 0.11); */
}