.blog__image {
  scale: 1;
  transition: all 0.3s ease;
}
.blog__image:hover {
  scale: 1.02;
}
.slider__trip .blog-title {
  font-size: 18px !important;
  /* font-family: 'Roboto', sans-serif; */
}

.slider__trip .blog-para {
  font-size: 15px !important;
  /* font-family: 'Roboto', sans-serif; */
}
.slider__trip .blog-div {
  margin-top: -6px !important;
  margin-right: 20px !important;
}
.trip-container {
  padding: 10px;
  overflow: hidden;
}
.trip {
  padding: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
}
.trip .icon {
  color: #ffca00;
}
.trip h5 {
  font-weight: 600;
  text-transform: capitalize;
}
.trip:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
}
.trip img {
  width: 100%;
  border-radius: 10px;
  height: 280px;
  object-fit: cover;
  object-position: left;
}
.trip h5 {
  margin: 10px 0;
  font-size: 18px;
}
.trip-det {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.trip-det p {
  display: flex;
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
}
.trip-dep {
  width: 75%;
}
.trip button {
  background-color: #ffca00;
  outline: transparent;
  border: transparent;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    opacity: 0.8;
  }
}
.trip-price {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  background-color: #303134;
  color: #fff;
}
.slider__trip {
  position: relative;
}
.slider-btns {
  right: 10px;
  bottom: 0px;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  height: 50px;
}
.slider__trip .slider-left,
.slider__trip .slider-right {
  width: 40px;
  height: 40px;
  background-color: #f7f7f7;
  color: grey;
  border: 1px solid grey;
  border-radius: 100px;
  margin-left: 20px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ffca00;
    color: #000;
  }
}

@media screen and (max-width: 992px) {
  .trip h5 {
    font-size: 14px;
  }
  .trip-price {
    font-size: 14px;
  }
  .slider-btns {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .trip h5 {
    font-size: 14px;
  }
  .trip i {
    font-size: 14px;
  }
  .trip p {
    font-size: 12px;
  }
}
