.hero-slider {
  z-index: 10;
}
.carousel-item {
  height: 100vh !important;
  position: relative;
}

.carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  /* semi-transparent black overlay */
}

.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 478px) {
  .carousel-item {
    height: 50vh !important;
  }
}
