.customise-label {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #ffaf23;
  color: #333;
  padding: 4px 10px;
  border-radius: 5px;
  font-weight: 600;
}
.category-banner {
  height: calc(100vh - 200px);
  background-position: center !important;
  background-size: cover !important;
}
.category__cont h2 {
  padding: 10px;
}

.category__cont select {
  color: #333333;
  border: 1px solid #333333;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 5px;
  outline: none;
}

.trip__container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 360px;
  padding-left: 0;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  cursor: pointer;
}

.trip__img__container {
  border-radius: 10px;
  overflow: hidden;
  width: 375px;
  height: 200px;
  margin-left: -10px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.other__content {
  margin-bottom: 10px;
  padding: 10px !important;
  width: 100% !important;
}

.dates p {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0;
}

.category-page-trip img {
  object-fit: cover;
  object-position: left;
}

@media only screen and (max-width: 600px) {
  .dates p {
    font-size: 12px;
  }
  .trip__title {
    font-size: 15px !important;
  }
}
