.secret-sauce-container {
  padding: 50px;
  padding-top: 0;
}
.secret-sauce {
  padding: 20px;
}
.secret {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  padding: 20px;
  cursor: pointer;
  min-height: 380px;
  border-radius: 15px;
}
.secret-sauce h4 {
  font-size: 16px;
  font-weight: bold;
}
.secret-sauce p {
  font-size: 14px;
}
