.sticky-wa-icon {
  position: fixed;
  right: 20px;
  bottom: 30px;
  background-color: rgb(173, 233, 173);
  border-radius: 100px;
  padding: 10px;
  z-index: 9999999;
  cursor: pointer;
  &:hover {
    background-color: rgb(153, 232, 153);
  }
}
.sticky-wa-icon .icon {
  font-size: 45px;
  color: green;
}
.footer {
  background-color: #333333;
  padding: 50px 100px;
  position: relative;
}

.footer h6 {
  font-size: 0.9rem;
  margin-top: 10px;
}

.footer p {
  margin: 0;
  font-size: 0.8rem;
  color: grey;
  font-weight: 500;
  text-align: justify;
  text-justify: inter-word;
  line-height: normal;
}

.social__media__links .icon {
  background-color: #666666;
  font-size: 40px;
  padding: 10px;
  margin: 0 5px;
  border-radius: 100px;
  color: #000;
  scale: 1;
  &:hover {
    scale: 1.1;
    background-color: #999;
  }
}

.social__media__links i:hover {
  scale: 1;
}

.quick__links p {
  font-size: 16px;
  font-weight: 200;
  margin: 10px 0;
  color: #fff;
}

.copyright__text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #000;
  color: white;
  padding: 20px 100px !important;
}
.left__border {
  border-left: 2px solid #fff;
}
.footer-blog {
  cursor: pointer;
  text-decoration: underline !important;
}
.footer-blog:hover {
  color: #666;
}

@media screen and (max-width: 600px) {
  .footer {
    background-color: #333333;
    padding: 30px 20px;
  }
  .email__number__website {
    width: 100%;
  }
  .email__number__website p {
    font-size: 10px;
  }
  .left__border {
    border-left: 0;
  }
  .copyright__text {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
  }

  .sticky-wa-icon .icon {
    font-size: 30px;
  }
}
