.upcoming__trips {
  overflow: hidden;
}
.upcoming__trips .category__heading {
  color: #333333;
  font-size: 15px;
}
.custom-select {
  width: 80px;
  text-align: center;
  border-radius: 100px !important;
  padding: 5px 10px !important;
}
.custom-select option {
  text-align: start;
}
.heading__date__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.heading__date__button select {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background: url("../../images/LandingPage/dropdown.png") no-repeat !important;
  background-position: calc(100% - 4px) center !important;
  background-size: 15px !important;
  padding-right: 25px !important;
}

.heading__date__button i {
  position: absolute;
}

.upcoming__trips select {
  padding: 5px 8px;
  border-radius: 10px;
  border: 2px solid #333333;
  color: #333333;
  background-color: transparent;
  font-size: 18px;
  margin: 0 15px;
}

.upcoming__trips .view_all {
  padding: 5px 25px;
  background-color: #fecb2a;
  color: #333333;
}

.upcoming__trips .view_all:hover {
  background-color: #ffca00;
}

@media screen and (max-width: 768px) {
  .upcoming__trips {
    overflow: hidden;
  }
  .heading__date__button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .upcoming__trips span {
    font-size: 4vw;
  }

  .upcoming__trips select {
    font-size: 12px;
  }

  .upcoming__trips button {
    padding: 8px 20px;
    font-size: 12px;
  }
  .heading__date__button select {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background: url("../../images/LandingPage/dropdown.png") no-repeat !important;
    background-position: calc(100% - 4px) center !important;
    background-size: 13px !important;
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .heading__date__button h2 {
    font-size: 18px;
  }
}
