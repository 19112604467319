.customised-container {
  padding: 70px 100px;
}
.customised-trips-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
}
.customised-trips {
  width: 15%;
  height: 300px;
  background-position: center !important;
  background-size: cover !important;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.customised-trips:hover {
  box-shadow: 0 5px 25px rgba(255, 202, 0, 0.7);
}
.bckd {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
}
.bckd h4 {
  font-weight: 600;
  z-index: 25;
  font-size: 14px;
  text-transform: uppercase;
}
.bckd::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0.5;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 65.62%,
    rgba(0, 0, 0, 1) 94%,
    rgba(0, 0, 0, 1) 96%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
  border-radius: 10px;
}
@media screen and (max-width: 992px) {
  .customised-container {
    padding: 70px 10px;
  }
  .customised-trips {
    width: 47%;
  }
}
