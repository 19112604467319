.hero__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
}

.other__section {
    height: 50vh;
    margin-top: 40px;
    /* margin-top: 15vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.typewriter-text {
    color: #fff;
    text-align: center;
    margin-bottom: -30px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.search {
    position: relative;
    margin-top: 100px;
}

.search__field {
    width: 45vw;
    height: 4vw;
    border: 1px solid #fff !important;
    border-radius: 5px;
    padding: 8px;
    padding-left: 20px;
    font-size: 1.5vw;
    font-weight: 400;
    color: #000;
    background: #fff;
    position: relative;
    font-family: 'Montserrat', sans-serif;
}

.search__location {
    position: relative;
    margin-top: 8vh;
    margin-bottom: 10vh;
    position: relative;
    overflow: hidden;
}

/* .search__location::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 9%;
    height: 100% !important;
    background-color: #eeb419;
    border-radius: 0 5px 5px 0 !important;
    z-index: 10;
} */

.search__location input {
    border: 0;
    outline: none;
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0.322);
}


.search__location input::placeholder {
    font-size: 1.3vw;
    color: #fff;
}

.search__location i {
    font-size: 22px !important;
    position: absolute;
    right: 0;
    background: #eeb419 !important;
    padding: 20px !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    background-color: transparent;
    z-index: 0;
}

.search .searching__box {
    position: absolute;
    top: 155px !important;
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 280px;
    overflow-y: scroll;
}

.search .searching__box div {
    cursor: pointer;
}

.search .searching__box div:hover {
    background-color: #ebebeb;
}

.search .searching__box p {
    font-size: 20px;
    color: #333333;
    margin: 5px;
}

.site__review {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 50px;
}

.img__review {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img__review i {
    font-size: 35px;
    margin-bottom: 10px;
}

.img__review span,
.img__review p {
    font-size: 20px;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
}

.book__now__btn__get__itinerary {
    margin-top: 230px !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.book__now__btn__get__itinerary button {
    padding: 14px 30px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    border: 0;
    margin: 5px 25px;
    background-color: #ffca00;
    color: black;
}
.book__now__btn__get__itinerary a {
    padding: 14px 30px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    border: 0;
    margin: 5px 25px;
    background-color: #ffca00;
    color: black;
}

.book__now__btn__get__itinerary button:hover {
    background-color: #fecb2a;
    color: #ffffff;
}
.book__now__btn__get__itinerary a:hover {
    background-color: #fecb2a;
    color: #ffffff;
}

@media screen and (max-width:600px) {
    .search .searching__box {
        top: 115px !important;
    }
    .hero__section {
        height: 80vh;
        margin-top: 60px;
    }

    .book__now__btn__get__itinerary button {
        padding: 10px 20px;
        font-size: 3.5vw;
        margin: 5px 25px;
    }
    .book__now__btn__get__itinerary a {
        padding: 10px 20px;
        font-size: 3.5vw;
        margin: 5px 25px;
    }

    .typewriter-text {
        color: #fff;
        text-align: center;
        margin-bottom: -60px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .typewriter-text h1 {
        font-size: 2rem;
    }

    .search__field {
        width: 80vw;
        height: 10vw;
        font-size: 3vw;
        font-weight: 400;
        margin-top: 0;
    }
/* 
    .search__location::before {
        width: 42px;
        height: 41px;
        top: 0;
        right: 0px;
        border-radius: 0 5px 5px 0 !important;
    } */

    .search__location i {
        font-size: 18px !important;
        padding: 12px !important;
    }

    .search__location input::placeholder {
        font-size: 3.5vw;
    }

    .search .searching__box p {
        font-size: 14px;
    }

    .img__review {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .img__review i {
        font-size: 2rem;
    }

    .img__review span,
    .img__review p {
        font-size: .7rem;
    }
}

@media screen and (max-width:400px) {
    .typewriter-text {
        color: #fff;
        text-align: center;
        margin-bottom: -40px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .typewriter-text h1 {
        font-size: 1.8rem;
    }
}